import React from "react"
import { Link, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import ContactCard from "../components/cards/contactcard"

import Layout from "../components/layout"

const IndexPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        file(sourceInstanceName: { eq: "images" }, name: { eq: "BGsocial" }) {
          childImageSharp {
            fluid(quality: 100, maxHeight: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  console.log(data)
  const imageData = data.file.childImageSharp.fluid

  return (
    <Layout>
      <img src="/images/main.webp" className="mx-auto" />

      <div className="mt-8">
        <ContactCard />
        <div className=" bg-white mx-auto max-w-lg shadow-lg rounded-lg overflow-hidden">
          <div className="sm:flex sm:items-center px-2 py-4">
            <div className=" text-left sm:text-left sm:flex-grow">
              <p>
                2008, waking up at night, half-dream state: I can see very
                clearly with photographic accuracy - I am standing in the middle
                of a very big and strange vortex, all this huge mass is in
                constant motion, nothing looks like it did before, as if pure
                energy has a constantly moving and changing structure. I kept
                wondering what it was? ... This icon was so vivid, that I did
                not rest for years, I kept trying to draw it and still continue
                ...
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
